//  ===========================================
//  Typography
//  ===========================================

html,
body,
p,
ul,
h1,
h2,
h3,
h4,
h5,
span,
.button {
	font-family: $font-primary;
}

h1 {
	font-size: 40px;
	color: $color-red;
	font-weight: 700;
	margin: 0 0 20px 0;
}
