//  ===========================================
//  Error
//  ===========================================

.message {
	width: 100%;
	// height: 45px;
	display: block;
	&__container {
		p {
			margin: 0;
			@include round-corner;
			margin: 0;
			color: #fff;
			font-weight: 700;
			text-align: center;
			padding: 8px;
			margin-bottom: 15px;
			&.message__error {
				background: $color-red;
			}
			&.message__success {
				background: $color-green;
			}
			&.message__alert {
				background: $color-yellow;
			}
		}
	}
}
