//  ===========================================
//  Fixes
//  ===========================================

html {
	box-sizing: border-box;
}

html {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	font-weight: 400;
}

*,
*:before,
*:after {
	box-sizing: inherit; // box-sizing fix
}

//  ===========================================
//  Variables
//  ===========================================

// --------------  Layout //
$site-width: 1000px;
$site-width--responsive: 660px;

// --------------  Fonts //

@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
$font-primary: 'Nunito', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// --------------  Colours //

$color-red: #fe5858;
$color-green: #63cab9;
$color-purple: #635d9d;
$color-yellow: #ffb548;
$color-grey--light: #f7f7f7;
$color-grey--medium: #808080;
$color-grey--dark: #404040;

//  ===========================================
//  Mixins
//  ===========================================

@mixin round-corner {
	border-radius: 3px;
}

@mixin button-colour($buttonColor) {
	background-color: $buttonColor;
	&:hover {
		background-color: darken($buttonColor, 33%);
	}
}

@mixin input-style {
	padding: 12px;
	@include round-corner;
	border: 1px solid $color-grey--medium;
	background: $color-grey--light;
	&:focus {
		border: 1px solid $color-green;
	}
}
