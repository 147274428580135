//  ===========================================
//  Home Recipe Entry
//  ===========================================

.recipe__entry {
	background: $color-grey--light;
	border-radius: 3px;
	margin-bottom: 20px;
	padding: 20px;
	position: relative;
	transition: all 0.25s ease-in;
	display: flex;
	flex-wrap: wrap;
	.recipe__meta {
		@media only screen and (max-width: $site-width--responsive) {
			order: 2;
		}
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		div {
			flex-basis: content;
			align-self: center;
			margin-right: 12px;
			@media only screen and (max-width: $site-width--responsive) {
				flex-basis: 50%;
				margin-bottom: 10px;
			}
		}
		svg {
			width: 24px;
			height: 24px;
		}
		.recipe__created {
			flex-grow: 1;
		}
		.recipe__time,
		.recipe__created {
			span {
				font-weight: 700;
				color: $color-purple;
			}
		}
		.recipe__type.FishDish svg {
			fill: $color-yellow;
		}
		.recipe__type.MeatDish svg {
			fill: $color-red;
		}
		.recipe__type.VegDish svg {
			fill: $color-green;
		}
	}
	.recipe__title {
		@media only screen and (max-width: $site-width--responsive) {
			order: 1;
			margin-bottom: 15px;
		}
		flex-basis: 100%;
		h3 {
			margin: 0;
			font-size: 34px;
			font-weight: 300;
			a {
				text-decoration: none;
				color: $color-grey--dark;
				z-index: 1;
				&:hover {
					color: $color-purple;
				}
			}
		}
	}
	.recipe__list-button {
		margin-top: 12px;
		@media only screen and (max-width: $site-width--responsive) {
			order: 3;
		}
	}
}

//  ===========================================
//  Recipe rating system homepage
//  ===========================================

.recipe__rating {
	display: flex;
	span {
		align-self: center;
		margin-right: 5px;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		li {
			display: inline-flex;
			svg {
				align-self: center;
			}
		}
		-moz-user-select: none;
		-webkit-user-select: none;
		> li svg {
			fill: $color-grey--medium;
		}
		> li.selected svg {
			fill: $color-yellow;
		}
	}
}
