//  ===========================================
//  Home Recipe
//  ===========================================

.recipe__title-bar {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 30px;
	h1 {
		flex-grow: 1;
		align-self: center;
		margin: 0;
	}
	a {
		flex-shrink: 1;
		align-self: center;
		margin-left: 15px;
	}
	select {
		// https://www.w3schools.com/howto/howto_custom_select.asp maybe improve this in the future?
		flex-shrink: 1;
		align-self: center;
		width: 240px;
		-moz-appearance: none;
		-webkit-appearance: none;
		background: $color-grey--light;
		background: url(./../img/sort-down.png) 95% no-repeat #eee;
		border: 1px solid $color-grey--medium;
		@include round-corner;
		padding: 8px;
		&:focus {
			outline: none;
		}
	}
}
