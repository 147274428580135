//  ===========================================
//  recipe form SVG radio buttons
//  ===========================================

.recipe__form-type form {
	display: flex;
	margin-top: 10px;
	input[type='radio'] {
		opacity: 0;
		width: 0;
		height: 0;
		&:active ~ label {
			opacity: 1;
		}
		&:checked ~ label {
			opacity: 1;
			.fishDish {
				fill: $color-yellow;
			}
			.vegDish {
				fill: $color-green;
			}
			.meatDish {
				fill: $color-red;
			}
		}
	}
	label {
		box-sizing: border-box;
		cursor: pointer;
		opacity: 0.33;
		transition: all 0.25s ease-in-out;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.5;
		}
		svg {
			width: 30px;
			height: 30px;
		}
	}
	div {
		display: flex;
		margin-right: 10px;
	}
}
