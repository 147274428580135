//  ===========================================
//  Shopping List
//  ===========================================

.shopping-container {
	.shopping__title {
		display: flex;
		justify-content: space-between;
		h2 {
			color: $color-red;
			margin: 0;
		}
		a {
			align-self: center;
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin-bottom: 10px;
		li {
			border-bottom: 1px dotted $color-grey--medium;
			padding: 10px 0;
			font-weight: 700;
			color: $color-purple;
			display: inline-flex;
			width: 100%;
			justify-content: space-between;
		}
		+ a {
			margin-bottom: 10px;
		}
	}
	// Style for the recipe page
	form {
		display: flex;
		align-items: flex-start;
		input {
			flex-grow: 1;
			@include input-style;
			margin: 0 15px 15px 0;
		}
	}
	#deleteIngredients,
	#printShopping {
		float: right;
	}
}
