//  ===========================================
//  Global Layout
//  ===========================================

html,
body {
	height: 100%;
	margin: 0;
	color: $color-grey--dark;
}

svg {
	overflow: visible;
}

.site {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.site-content {
	flex: 1;
	z-index: 1;
}

//  ===========================================
//  Site Containers
//  ===========================================

.container {
	max-width: $site-width;
	width: 92%;
	margin: 0 auto;
	padding: 20px 0;
	display: flex;
	justify-content: space-between;
	align-content: flex-start;
	flex-wrap: wrap;
}

.recipe-container {
	width: 65.52%;
	@media only screen and (max-width: $site-width) {
		width: 100%;
	}
}

.shopping-container {
	width: 31.03%;
	@media only screen and (max-width: $site-width) {
		width: 100%;
	}
}

//  ===========================================
//  Header
//  ===========================================

header.header {
	width: 100%;
	background: $color-grey--light;
	z-index: 10;
	.header__logo {
		align-self: center;
		flex-grow: 1;
		svg {
			width: 311px;
			height: 43px;
		}
	}
	.header__search-form {
		align-self: center;
		display: flex;
		svg {
			width: 28px;
			height: 28px;
			margin-right: 20px;
			align-self: center;
		}
		input {
			padding: 10px;
			@include round-corner;
			outline: 0;
			border: 1px solid $color-grey--medium;
			width: 250px;
		}
		@media only screen and (max-width: $site-width--responsive) {
			width: 100%;
			margin-top: 10px;
			input {
				width: 100%;
				margin-right: 5%;
			}
		}
	}
	.header__recipe-save {
		align-self: flex-start;
		margin-left: 15px;
		@media only screen and (max-width: $site-width--responsive) {
			margin-left: 0;
			margin-top: 10px;
		}
	}
	.header__recipe-details {
		align-self: flex-start;
		@media only screen and (max-width: $site-width--responsive) {
			width: 100%;
		}
		.header__recipe-created {
			font-weight: 700;
			font-size: 18px;
			color: $color-purple;
			margin-top: 10px;
		}
		.header__recipe-updated {
			font-size: 14px;
			color: $color-red;
			margin-top: 3px;
			text-align: right;
		}
	}
}

//  ===========================================
//  Footer
//  ===========================================

footer {
	width: 100%;
	.container {
		padding: 0;
		p {
			font-size: 14px;
			line-height: 1.5;
			margin: 20px 0;
		}
		a {
			color: $color-grey--medium;
			text-decoration: none;
			&:hover {
				color: $color-grey--dark;
			}
		}
	}
}
