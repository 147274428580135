//  ===========================================
//  title and labels
//  ===========================================

.recipe__form-labels {
	display: flex;
	flex-wrap: wrap;
	&-title {
		flex-grow: 1;
		color: $color-purple;
		font-weight: 700;
		margin-bottom: 10px;
	}
	&-edit a {
		font-weight: 700;
		margin-right: 10px;
		color: $color-grey--medium;
		text-decoration: none;
		transition: all 0.25s ease-in;
		&:hover {
			color: darken($color-grey--medium, 33%);
		}
	}
	&-save a {
		color: $color-green;
		font-weight: 700;
		text-decoration: none;
		transition: all 0.25s ease-in;
		&:hover {
			color: darken($color-green, 33%);
		}
	}
	.recipe__form-title {
		width: 100%;
		margin-bottom: 20px;
		h1 {
			font-size: 32px;
			margin: 0;
		}
		input {
			width: 100%;
			@include input-style;
		}
	}
}

//  ===========================================
//  Recipe ratings for single recipes
//  ===========================================

.recipe__form-rating {
	@extend .recipe__rating;
	flex-direction: column;
	span {
		align-self: flex-start;
	}
	svg {
		width: 25px;
		height: 25px;
		margin-top: 15px;
		margin-right: 5px;
	}
	ul {
		-moz-user-select: none;
		-webkit-user-select: none;
		> li svg {
			fill: $color-grey--medium;
		}
		> li.hover svg {
			fill: lighten($color-yellow, 20%);
			cursor: pointer;
		}
		> li.selected svg {
			fill: $color-yellow;
		}
	}
}


//  ===========================================
//  recipe form details
//  ===========================================

.recipe__form-details {
	display: flex;
	flex-wrap: wrap;
	color: $color-purple;
	font-weight: 700;
	> div {
		margin-right: 20px;
		@media only screen and (max-width: $site-width--responsive) {
			width: 100%;
			margin-bottom: 15px;
		}
	}
	// Recipe form time and form
	.recipe__form-time {
		input {
			margin-top: 5px;
			@include input-style;
			width: 70px;
			margin-right: 5px;
		}
	}
	// Recipe form SVG checkbox maybe?
}

//  ===========================================
//  styling for the instructions
//  ===========================================

.recipe__form-instructions {
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 20px;
	counter-reset: section; /* Set a counter named 'section', and its initial value is 0. */
	h2 {
		margin: 20px 0 0;
		color: $color-red;
		@media only screen and (max-width: $site-width--responsive) {
			margin: 0;
		}
	}
	ol {
		padding: 0;
		margin: 0 0 15px;
		li {
			border-bottom: 1px dotted $color-grey--medium;
			padding: 10px 5px;
			display: flex;
			p {
				margin: 0;
				flex-grow: 1;
			}
			&::before {
				counter-increment: section; /* Increment the value of section counter by 1 */
				content: counter(section); /* Display the value of section counter */
				padding-right: 10px;
				font-weight: bold;
			}
			.edit-instruction {
				margin-right: 10px;
			}
			&.active {
				background: $color-grey--light;
			}
		}
	}
	form {
		display: flex;
		align-items: flex-start;
		margin: 10px 0 15px;
		textarea {
			flex-grow: 1;
			@include input-style;
			margin-right: 15px;
		}
	}
	#deleteRecipe {
		float: right;
	}
}
