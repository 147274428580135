//  ===========================================
//  Buttons
//  ===========================================

.button {
	@include round-corner;
	display: inline-flex;
	background-color: $color-grey--medium;
	padding: 10px;
	color: #fff;
	text-decoration: none;
	font-weight: 700;
	transition: all 0.25s ease-in;
	outline: 0;
	border: 0;
	cursor: pointer;
	svg {
		width: 12px;
		height: 12px;
		fill: #fff;
	}
	&:hover {
		background-color: $color-grey--dark;
	}
	&--green {
		@include button-colour($color-green);
	}
	&--purple {
		@include button-colour($color-purple);
	}
	&--red {
		@include button-colour($color-red);
	}
	&--delete {
		background-color: $color-grey--medium;
		&:hover {
			background-color: $color-red;
		}
	}
}

.recipe--list-button a {
	display: flex;
	font-weight: 700;
	text-decoration: none;
	color: $color-green;
	transition: all 0.25s ease-in;
	svg {
		width: 13px;
		height: 13px;
		align-self: center;
		margin-right: 4px;
		fill: $color-green;
		transition: all 0.25s ease-in;
	}
	&:hover {
		color: darken($color-green, 33%);
		svg {
			fill: darken($color-green, 33%);
		}
	}
}

.inline-button {
	display: inline-flex;
	align-items: center;
	color: $color-grey--medium;
	text-decoration: none;
	transition: all 0.25s ease-in;
	font-weight: 700;
	height: 20px;
	svg {
		fill: $color-grey--medium;
		transition: all 0.25s ease-in;
		align-self: center;
	}
	&[class*='delete'] svg {
		width: 22px;
		height: 22px;
		margin-left: 5px;
	}
	&[class*='edit'] svg {
		width: 22px;
		height: 22px;
		margin-left: 10px;
		fill: $color-yellow;

	}
	&:hover {
		color: darken($color-grey--medium, 33%);
		svg {
			fill: darken($color-grey--medium, 33%);
		}
	}
	&--green {
		color: $color-green;
		svg {
			fill: $color-green;
			margin-right: 5px;
			width: 14px;
			height: 14px;
		}
		&:hover {
			color: darken($color-green, 33%);
			svg {
				fill: darken($color-green, 33%);
			}
		}
	}
	&--delete {
		color: $color-grey--medium;
		&:hover {
			color: darken($color-red, 0%);
			svg {
				fill: darken($color-red, 0%);
			}
		}
	}
}
